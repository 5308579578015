.t-wrapper {
  padding: 0 3rem 0 3rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  /* margin-top: 2rem; */
  gap: 2rem;
}

.t-heading {
  /* align-self: start; */
  align-items: center;
}

.t-heading > * {
  font-size: 2rem;
  font-weight: bold;
}

.t-heading span:nth-child(2) {
  color: var(--orange);
}

.t-blur1 {
  left: 50rem;
  top: 16rem;
}

.t-blur2 {
  left: 0rem;
  top: 9rem;
}

.testimonial {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5rem;
  width: 28rem;
  height: 18rem;

  /* stylee same as work.css */
  background: rgba(255, 255, 255, 0.26);
  border: 7px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  cursor: pointer;
}

.testimonial > img {
  width: 20rem;
  height: 8rem;
  border-radius: 2%;
  padding-bottom: 2rem;
}

button {
  cursor: pointer;
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: linear-gradient(180deg, #23d4f3 26.71%, #31d5eb 99.36%);
}

button:hover {
  background: white;
  cursor: pointer;
  color: #0b778a;
  border: 1px solid #157788;
}

.testimonial .btn {
  color: var(--gray);
  display: flex;
  gap: 2rem;
  margin-top: -2rem;

  /* flex-direction: column; */
}

.testimonial .text1 {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;

  /* flex-direction: column; */
}

.testimonial .text2 {
  padding-bottom: 2rem;

  /* flex-direction: column; */
}

.t-wrapper .swiper {
  width: 100%;
  height: 70%;
}

.t-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active {
  background: var(--orange);
}

@media screen and (max-width: 480px) {
  .t-wrapper {
    padding: 0;
  }

  .t-heading {
    /* align-self: start; */
    align-items: center;
  }

  .testimonial > img {
    width: 70px;
    height: 70px;
  }
}
