.portofolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}
/* heading */
.portofolio span:nth-child(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portofolio span:nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

/* slider */
.portofolio .swiper {
  overflow: visible !important;
}

.portofolio-slider {
  margin-top: 3rem;
  width: 100%;
}

.portofolio-slider .swiper-slide {
  width: 20rem;
}

.portofolio img {
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
  cursor: pointer;
}
