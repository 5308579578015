.contact-form {
  padding: 0 3rem 0 3rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.c-right > form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.c-right .user {
  width: 20rem;
  height: 2rem;
  padding: 0.3em;
  outline: none;
  border: 2px solid var(--orange);
  border-radius: 8px;
  font-size: 12px;
}

textarea {
  height: 4rem !important;
}

.c-blur1 {
  top: 1rem;
  left: 8rem;
}

.c-blur1 {
  top: 1rem;
  left: 8rem;
}

@media screen and (max-width: 480px) {
  .c-right {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
}
